<template>
<div class="wechat">
  <img
      src="../../../../assets/pc/images/login/loginLogo.png"
      alt=""
    />
  <!--    右侧头部区域-->
  <div class="login-right-top">
    <span>欢迎登录</span>
    <router-link to="normal">其他登录方式</router-link>
  </div>
<!--  右侧二维码区域-->
  <div class="code">
    <div class="code-top">
      <img src="~@/assets/pc/images/login/login-wechat-wb.png" alt="">
      <span>微信扫码登录</span>
    </div>
    <div class="code-middle" id="qrcode">
      <img :src="imgCode" alt="">
    </div>
    <div class="code-bottom">
      <img src="~@/assets/pc/images/login/login-swcode.png" alt="">
      <span>扫一扫</span>
    </div>
  </div>
</div>
</template>

<script>
import { request } from '@/network';
// import QRCode from 'qrcodejs2'
import { getnoreadmessage } from '@/assets/public/utils/token';
export default {
  name: 'loginRightWechat',
  data () {
    return {
      qrcodedata: '',
      imgCode: '',
      codeIntervalCode: null
    };
  },
  created () {
    this.getCodeData();
  },
  methods: {
    async getCodeData () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/qrCode'
      });
      this.imgCode = res.data.base64;
      this.qrcodedata = res.data.code;
    },
    async getCodeStatus () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/getLoginState',
        params: {
          code: this.qrcodedata,
          sysType: this.$sysType
        }
      });
      if (res.code === 200) {
        clearInterval(this.codeIntervalCode);
        this.$store.commit('login/setUser', [ res.data, true ]);
        this.$message.success('登录成功');
        this.getLoginInfo();
        return this.$router.push('/index');
      } else if (res.code === 1002) {
        clearInterval(this.codeIntervalCode);
        const str = res.data;
        this.$router.push({
          path: '/login/loginBind',
          query: str
        });
      }
    },
    openMesBox (mes) {
      this.$alert(mes, '', {
        confirmButtonText: '确定',
        center: true,
        callback: action => {
          this.getCodeData();
        }
      });
    },
    async getLoginInfo () {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/getLoginInfo'
      });
      if (res.code !== 200) return this.$message.error('获取用户信息失败');
      getnoreadmessage(request, res.data.id).then(resMessage => {
        localStorage.setItem('notRead', resMessage);
        this.$store.commit("personal/setNotRead", resMessage);
        this.$store.commit('personal/setImgUrl', res.data.avatar);
      });
    },
    beforeDestroy () {
      clearInterval(this.codeIntervalCode);
    }
  },
  watch: {
    qrcodedata () {
      this.codeIntervalCode = setInterval(() => {
        this.getCodeStatus();
      }, 3000);
    }
  }
};
</script>

<style scoped>
/*总体样式*/
.wechat {
  /* height: 426px; */
  width: 320px;
  margin-left: 139px;
  padding: 40px 0;

}
/*右侧头部样式*/
.login-right-top {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 15px; */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  /* border-bottom: 2px solid #000000; */
}
.login-right-top span{
  /*align-self: center;*/
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
}
.login-right-top a{
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #156ED0;
  cursor: pointer;
}
/*右侧二维码区域*/
.code {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-top: 44px;
  margin-left: 85px;
  text-align: center;
}
.code-top img{
  width: 28px;
  height: 22px;
}
.code-top span{
  margin-left: 5px;
}
.code-middle img{
  width: 150px;
  height:150px;
  /*background-color: pink;*/
  margin: 15px 0px;
}
.code-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 36px;
  background: #B2B2B2;
  border-radius: 18px;
}
.code-bottom span{
  margin-left: 7px;
  color: #FFFFFF;
}
</style>
